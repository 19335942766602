<template>
  <div>
    <span class="ml-2"> {{ $t("email") }} </span>
    <v-row class="mb-sm-n4 mb-n10">
      <v-col cols="12" sm="8" md="5">
        <v-form class="d-flex">
          <v-text-field
              style="min-width: 200px"
              v-model="userMail"
              :filled="!isSetEmailEnabled"
              outlined
              :disabled="!isSetEmailEnabled"
              :readonly="!isSetEmailEnabled"
              dense
          >
            <template v-slot:append-outer >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information-outline</v-icon>
                </template>
                {{isSetEmailEnabled ? $t("toolTips.emailForSendingNotifications") : $t("toolTips.changeEmailInfo") }}
              </v-tooltip>
            </template>
          </v-text-field>
          <v-tooltip v-if="isSetEmailEnabled"
              bottom
              :disabled="isEmailValid"
          >
            <template v-slot:activator="{ on, attrs }">
              <div style="height: 33px" v-bind="attrs" v-on="on">
                <v-btn
                    style="margin-top: 2px"
                    class="ml-3"
                    @click="sendEmail"
                    :loading="isEmailSaveLoading"
                    :disabled="!isEmailValid"
                >
                  {{ $t("buttons.save") }}
                  <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                  </template>
                </v-btn>
              </div>
            </template>
            <span> {{ $t("toolTips.invalidEmail") }} </span>
          </v-tooltip>
        </v-form>
      </v-col>
      <v-col sm="4" md="7" cols="0"></v-col>
    </v-row>
    <v-snackbar right top app color="success" v-model="emailChangedSuccessfully">
      {{ $t("notificationSnackbars.emailChangedSuccessfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="emailChangedSuccessfully = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="emailChangedError">
      {{ $t("notificationSnackbars.emailChangedError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="emailChangedError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {patchUser} from "../../../services/backendService";

export default {
  data() {
    return {
      userMail: "",

      isEmailSaveLoading: false,

      emailChangedSuccessfully: false,
      emailChangedError: false,
    };
  },
  created() {
    this.fetchUserMail();
  },
  computed: {
    isSetEmailEnabled() {
      return !this.$store.state.user.userMail;
    },
    isEmailValid() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(this.userMail);
    },
  },
  methods: {
    fetchUserMail() {
      this.userMail = this.$store.state.user.userMail;
    },
    async sendEmail() {
      this.isEmailSaveLoading = true;
      const bodyToSend = {
        email: this.userMail,
      };
      const response = await patchUser(bodyToSend);
      if (!response) {
        this.emailChangedError = true;
      } else {
        this.emailChangedSuccessfully = true;
        await this.$store.dispatch("changeUserEmail", this.userMail);
      }

      this.isEmailSaveLoading = false;
    }
  }
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
